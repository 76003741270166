import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  card: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
    padding: "1rem 1.5rem",
  },
  tableWrapper: {
    height: "100%",
  },
  container: {
    height: "80%",
    maxHeight: 1000,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    height: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
  tableTitleWrapper: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "1rem",
    borderRadius: "1.25rem 1.25rem 0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableWidgetCont: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem"
  },
  tableRow: {
    cursor: "pointer",
    height: "30px",
  },
}));
