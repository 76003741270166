import React from "react";
import Upload from "../assests/icons/uploadIcon.png";
import Add from "../assests/icons/addIcon.png";
import Close from "../assests/icons/closeIcon.png";
import Scanner from "../assests/icons/scannerIcon.png";
import Person from "../assests/icons/PersonIcon.png";
import supplierIcon from "../assests/icons/supplierIcon.svg";
import GenerateDueIconSvg from "../assests/icons/generateDues.svg";
import PreviousDueIconSvg from "../assests/icons/previousDues.svg";
import Home from "../assests/icons/home.png";
import Done from "../assests/icons/done.png";
import Wallet from "../assests/icons/wallet.png";
import ReportAllIconSVG from "../assests/icons/reportAllIcon.svg";
import ReportSingleIconSVG from "../assests/icons/reportSingleIcon.svg";
import SendRemindersToAll from "../assests/icons/sendRemindersToAll.png";
import bankSVG from "../assests/icons/bank.svg";
import emailSVG from "../assests/icons/email.svg";
import activateReservedContractSVG from "../assests/icons/activateReservedContract.svg";
import newContractSVG from "../assests/icons/newContract.svg";
import endContractSVG from "../assests/icons/endContract.svg";
import darkSVG from "../assests/icons/dark.svg";
import yellowSVG from "../assests/icons/yellow.svg";
import commentIcon from "../assests/icons/comments-icons.png";
import postponIcon from "../assests/icons/postpon.png";
import printSVG from "../assests/icons/print.svg";
import searchSVG from "../assests/icons/search.svg";

import Logo_English_Language from "../assests/icons/english.png";
import Logo_French_Language from "../assests/icons/french.png";
import Logo_German_Language from "../assests/icons/german.png";
import SunIcon from "./Sun";

export const UploadIcon = (props) => {
  return <img alt="Logo" src={Upload} {...props} />;
};

export const IconAdd = (props) => {
  return <img alt="Logo" src={Add} {...props} />;
};
export const HomeIcon = (props) => {
  return (
    <img
      alt="Logo"
      src={Home}
      {...props}
      style={{ height: "15px", width: "15px" }}
    />
  );
};
export const DoneIcon = (props) => {
  return (
    <img
      alt="Logo"
      src={Done}
      {...props}
      style={{ height: "15px", width: "15px" }}
    />
  );
};
export const WalletIcon = (props) => {
  return (
    <img
      alt="Logo"
      src={Wallet}
      {...props}
      style={{ height: "15px", width: "15px" }}
    />
  );
};

export const CloseIcon = (props) => {
  return <img alt="close" src={Close} {...props} />;
};

export const IconScanner = (props) => {
  return (
    <img
      alt="close"
      src={Scanner}
      {...props}
      style={{ height: "40px", width: "40px" }}
    />
  );
};

export const PersonIcon = (props) => {
  return <img alt="PersonIcon" src={Person} {...props} />;
};

export const SupplierIcon = (props) => {
  return <img alt="TruckIcon" src={supplierIcon} {...props} />;
};

export const GenerateDueIcon = (props) => {
  return <img alt="TruckIcon" src={GenerateDueIconSvg} {...props} />;
};

export const CommentsIcon = (props) => {
  return <img alt="Comments icon" src={commentIcon} {...props} />;
};

export const PostponIcon = (props) => {
  return <img alt="Postpone icon" src={postponIcon} {...props} />;
};

export const PreviousDueIcon = (props) => {
  return <img alt="TruckIcon" src={PreviousDueIconSvg} {...props} />;
};

export const ReportAllIcon = (props) => {
  return <img alt="Logo" src={ReportAllIconSVG} {...props} />;
};
export const ReportSingleIcon = (props) => {
  return <img alt="Logo" src={ReportSingleIconSVG} {...props} />;
};
export const ReminderToAll = (props) => {
  return (
    <img
      alt="Logo"
      src={SendRemindersToAll}
      {...props}
      style={{ width: "160px", height: "40px" }}
    />
  );
};

export const BankIcon = (props) => {
  return <img alt="Logo" src={bankSVG} {...props} />;
};

export const EmailIcon = (props) => {
  return <img alt="Logo" src={emailSVG} {...props} />;
};

export const ActivateReservedIcon = (props) => {
  return <img alt="Logo" src={activateReservedContractSVG} {...props} />;
};

export const StartNewContractIcon = (props) => {
  return <img alt="Logo" src={newContractSVG} {...props} />;
};

export const EndContractIcon = (props) => {
  return <img alt="Logo" src={endContractSVG} {...props} />;
};

export const EnglishLanguageIcon = () => (
  <img
    title="English"
    alt="English"
    src={Logo_English_Language}
    width="20"
    height="20"
  />
);
export const FrenchLanguageIcon = () => (
  <img alt="French" src={Logo_French_Language} width="20" height="20" />
);
export const GermanLanguageIcon = () => (
  <img alt="German" src={Logo_German_Language} width="20" height="20" />
);

export const YellowIcon = () => {
  return (
    <SunIcon />
  );
};

export const DarkIcon = (props) => {
  return (
    <img alt="dark icon" src={darkSVG} {...props} width="25" height="26" />
  );
};

export const SearchIcon = (props) => {
  return (
    <img alt="search icon" src={searchSVG} {...props} width="25" height="26" />
  );
};
export const PrintIcon = (props) => {
  return (
    <img alt="print icon" src={printSVG} {...props} width="25" height="26" />
  );
};
