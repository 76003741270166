import {
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { getTranslation } from "../../../heplers/translationHelper";
import * as Yup from "yup";
import { useState } from "react";
import { useCustomerManagementStyles } from "../customerManagementStyles";
import { Post } from "../../../actions";
import { Upsert_Customers_URL } from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import formatDistanceToNow from "date-fns/esm/formatDistanceToNow/index";
import { useEffect } from "react";
import Card from "../../../components/Card";

const AddCustomer = ({
  isEdit,
  setIsEdit,
  params,
  setParams,
  formData,
  setFormData,
}) => {
  const classes = useCustomerManagementStyles();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(getTranslation("Full Name is required")),
    phone: Yup.number().required(getTranslation("Phone Number is required")),
    email: Yup.string().email().required(getTranslation("Please enter a valid email address")),
  });

  const submit = (data, actions) => {
    actions.setSubmitting(true);
    Post(
      data,
      Upsert_Customers_URL,
      null,
      (res) => {
        actions.setSubmitting(false);
        setIsEdit(false);
        enqueueSnackbar(
          isEdit
            ? getTranslation(
              "Updated successfully",
              "Updated successfully",
              "Updated successfully"
            )
            : getTranslation(
              "Customer created successfully",
              "Customer created successfully",
              "Customer created successfully"
            ),
          {
            variant: "success",
          }
        );
        setParams({ ...params, pageNumber: 0 });
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          subscribeForNews: false,
        });
      },
      (err) => {
        actions.setSubmitting(false);
        if (!err || !err.data) return;
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
              "Something went wrong. Please try Again",
              "Something went wrong. Please try Again",
              "Something went wrong. Please try Again"
            ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  return (
    <Card title={isEdit ? getTranslation("Edit Customers", "Edit Customers", "Edit Customers") : getTranslation("Add Customers", "Add Customers", "Add Customers")}>
      <CardContent>
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={4} >
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      label={getTranslation(
                        "Full Name",
                        "Full Name",
                        "Full Name"
                      )}
                      name="fullName"
                      type="string"
                      size="small"

                      value={values.fullName}
                      onChange={handleChange}
                      error={touched.fullName && Boolean(errors.fullName)}
                      helperText={touched.fullName && errors.fullName}
                      inputProps={{
                        pattern: '^[A-Za-z\\s]+$', 
                        onKeyPress: (event) => {
                          const charCode = event.charCode;
                          if (!/[A-Za-z\s]/.test(String.fromCharCode(charCode))) {
                            event.preventDefault(); 
                          }
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={4} >
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      label={getTranslation("Email", "Email", "Email")}
                      name="email"
                      type="string"
                      size="small"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item sm={12} md={4} >
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      label={getTranslation(
                        "Phone Number",
                        "Phone Number",
                        "Phone Number"
                      )}
                      name="phone"
                      type="number"
                      size="small"
                      value={values.phone}
                      onChange={handleChange}
                      error={touched.phone && Boolean(errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />
                  </Grid>
                  <Grid item sm={12} md={4} >
                    <FormControlLabel
                      label={getTranslation(
                        "Subscribe for Newsletter",
                        "Subscribe for Newsletter",
                        "Subscribe for Newsletter"
                      )}
                      value={values.subscribeForNews}
                      name="subscribeForNews"
                      onChange={(val) => {
                        const { checked } = val.target;
                        setFieldValue("subscribeForNews", checked);
                      }}
                      control={
                        <Checkbox
                          checked={values.subscribeForNews}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                  <Grid item sm={12} md={12} >
                    <div className={classes.btnCont}>
                      <ButtonWithLoading
                        type="submit"
                        loading={isSubmitting}
                        onClick={handleSubmit}
                        title={
                          isEdit
                            ? getTranslation("Update", "Update", "Update")
                            : getTranslation("Add", "Add", "Add")
                        }
                        variant="contained"
                        color="primary"
                      />
                    </div>
                  </Grid>

                </Grid>
              </Form>

            );
          }}
        </Formik>
      </CardContent>

    </Card>
  );
};

export default AddCustomer;
