import { Box, Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { MyDialog } from "../../../../components/Dialog";
import { getTranslation } from "../../../../heplers/translationHelper";
import * as Yup from "yup";
import { MyTimePicker } from "../../../../components/TimePicker";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { Post } from "../../../../actions";
import { Upsert_TimeSlots_URL } from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import moment from "moment";
import { format } from "../../../../heplers/format";
import { daysConstant } from "../../../../constants/general";

const AddTimeSlotModal = ({
  open,
  handleClose,
  isEdit,
  setIsEdit,
  formData,
  setFormData,
  params,
  setParams,
  refreshTimeSlots
}) => {
  let { primaryColor,secondaryColor } = params;
  if(typeof primaryColor === 'undefined')
    primaryColor="E7424C"
    if(typeof secondaryColor === 'undefined')
    secondaryColor="ffffff"

  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = Yup.object().shape({
    startDateTime: Yup.string()
      .required("Start time is required")
      .test("startDateTime", "Start time must be 24 hours", (value) => {
        // const { end_time } = this.parent;
        return moment(value).isValid();
      }),
    endDateTime: Yup.string()
      .required("End time is required")
      .test("endDateTime", "End time must be 24 hours", function (value) {
        // const { end_time } = this.parent;

        return moment(value).isValid();
      }),
    maxGroup: Yup.number().min(1, "This field is required"),
    maxPersonPerGroup: Yup.number().min(1, "This field is required"),
    comments: Yup.string(),
  });

  const addTimeSlot = async (payload, actions, isLast) => {
 
   await Post(
      payload,
      Upsert_TimeSlots_URL,
      null,
      (res) => {
        actions.setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Added Successfully",
            "Added Successfully",
            "Added Successfully"
          ),
          {
            variant: "success",
          }
        );
      
        // setParams({ ...params, selectedDate: new Date(date), day: params.day });
        // setFormData({
        //   startDateTime: new Date(),
        //   endDateTime: new Date(),
        //   maxGroup: 0,
        //   maxPersonPerGroup: 0,
        //   comments: "",
        // });
        if(isLast){
        handleClose(false);
        
        if(refreshTimeSlots)
        refreshTimeSlots()}
      },
      (err) => {
        if (!err) return;
        actions.setSubmitting(false);
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
                "Something went wrong",
                "Something went wrong",
                "Something went wrong"
              ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  // const modifyHours = (date) => {
  //   const newDate = new Date(date);
  //   newDate.setHours(newDate.getHours() + 1);
  //   const modifiedDate = new Date(newDate).toISOString();
  //   return modifiedDate;
  // };

  const onSubmit = (values, actions) => {
    // const startHour = new Date(modifyHours(values.startDateTime)).getHours();
    actions.setSubmitting(true);
    // const dayTime = startHour > 12 ? 1 : 0;
    if(isEdit){
      const payload = {
        ...values,
        startDateTime: moment(values.startDateTime).format("kk:mm"),
        endDateTime: moment(values.endDateTime).format("kk:mm"),
      };
      addTimeSlot(payload, actions, i==values.selectedDays?.length -1 ).then(()=>{
        handleClose();

      });
    }
    else{
      for(var i=0; i<values.selectedDays?.length; i++){
        const day = values.selectedDays[i]
          const payload = {
            ...values,
            startDateTime: moment(values.startDateTime).format("kk:mm"),
            endDateTime: moment(values.endDateTime).format("kk:mm"),
            day: day.id,
            dayTime: params.dayTime,
          };
          addTimeSlot(payload, actions, i==values.selectedDays?.length -1 );
          
      }
    }
   
    // const payload = {
    //   ...values,
    //   startDateTime: moment(values.startDateTime).format("kk:mm"),
    //   endDateTime: moment(values.endDateTime).format("kk:mm"),
    //   day: params.day,
    //   dayTime: params.dayTime,
    // };
    // addTimeSlot(payload, actions);
  };

  return (
    <MyDialog
      title={
        isEdit
          ? getTranslation(
              "Update Time Slot",
              "Update Time Slot",
              "Update Time Slot"
            )
          : getTranslation("Add Time Slot", "Add Time Slot", "Add Time Slot")
      }
      open={open}
      onClose={() => handleClose(false)}
    >
      <div>
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>

              {isEdit || daysConstant.map((day,index) => {
                return (
                      <Grid item sm={4} md={3} lg={2} key={index} >
                          <FormControlLabel
                            label={day.title.substring(0,3)}
                            name="selectedDays"
                            onChange={(val) => {
                              const { checked } = val.target;
                              const arr = values.selectedDays;
                              if (checked) {
                                arr.push(day)
                                setFieldValue("selectedDays", arr);
                              }
                              else
                                setFieldValue("selectedDays", arr.filter(x => x.id != day.id));
                            }}
                            control={
                              <Checkbox
                                checked={values?.selectedDays?.findIndex(x => x.id == day.id)> -1}
                                style={{color:"#"+primaryColor}}
                              />
                            }
                          />
                          {touched.selectedDays && errors.selectedDays ? (
                            <div>{errors.selectedDays}</div>
                          ) : null}
                      </Grid>
                  )
                })}
                {isEdit ||
                <Grid item sm={4} md={3} lg={2} >
                  <FormControlLabel
                    label={"All"}
                    name="selectedDays"
                    onChange={(val) => {
                      const { checked } = val.target;
                      if (checked) {
                        setFieldValue("selectedDays", daysConstant);
                      }
                      else
                        setFieldValue("selectedDays", []);
                    }}
                    control={
                      <Checkbox
                        checked={values?.selectedDays?.length == daysConstant.length}
                        style={{ color: "#" + primaryColor }}
                      />
                    }
                  />
                  {touched.selectedDays && errors.selectedDays ? (
                    <div>{errors.selectedDays}</div>
                  ) : null}
                </Grid>
}

              </Grid>
<br/>
<br/>
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={6}>
                  <MyTimePicker
                    required
                    size="small"
                    ampm={false}
                    label={getTranslation(
                      "Start Time",
                      "Start Time",
                      "Start Time"
                    )}
                    value={values.startDateTime}
                    onChange={(val) => {
                      setFieldValue("startDateTime", val);
                    }}
                    inputVariant="standard"
                    renderInput={(params) => <TextField {...params} />}
                    id="startDateTime"
                    name="startDateTime"
                    error={
                      touched.startDateTime && Boolean(errors.startDateTime)
                    }
                    helperText={touched.startDateTime && errors.startDateTime}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={6}>
                  <MyTimePicker
                    required
                    ampm={false}
                    size="small"
                    label={getTranslation("End Time", "End Time", "End Time")}
                    value={values.endDateTime}
                    onChange={(val) => {
                      setFieldValue("endDateTime", val);
                    }}
                    id="endDateTime"
                    name="endDateTime"
                    error={touched.endDateTime && Boolean(errors.endDateTime)}
                    helperText={touched.endDateTime && errors.endDateTime}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="maxGroup"
                    label={getTranslation(
                      "Max Group",
                      "Max Group",
                      "Max Group"
                    )}
                    name="maxGroup"
                    type="number"
                    size="small"
                    value={values.maxGroup}
                    onChange={handleChange}
                    error={touched.maxGroup && Boolean(errors.maxGroup)}
                    helperText={touched.maxGroup && errors.maxGroup}
                    InputProps={{ inputProps: { min: 0} }}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="maxPersonPerGroup"
                    label={getTranslation(
                      "Max Person Per Group",
                      "Max Person Per Group",
                      "Max Person Per Group"
                    )}
                    name="maxPersonPerGroup"
                    type="number"
                    size="small"
                    value={values.maxPersonPerGroup}
                    onChange={handleChange}
                    error={
                      touched.maxPersonPerGroup &&
                      Boolean(errors.maxPersonPerGroup)
                    }
                    helperText={
                      touched.maxPersonPerGroup && errors.maxPersonPerGroup
                    }
                    InputProps={{ inputProps: { min: 0} }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    minRows={5}
                    multiline
                    id="comments"
                    label={getTranslation("Comment", "Comment", "Comment")}
                    name="comments"
                    type="string"
                    size="small"
                    value={values.comments}
                    onChange={handleChange}
                    error={touched.comments && Boolean(errors.comments)}
                    helperText={touched.comments && errors.comments}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <ButtonWithLoading
                  title={getTranslation("Submit", "Submit", "Submit")}
                  disabled={!isEdit?values.selectedDays.length==0:formData.day?false:true}
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                />
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </MyDialog>
  );
};

export default AddTimeSlotModal;
