import {

  SET_PRIMARY_COLOR,
  SET_SECONDARY_COLOR
} from "../constants/types";

const initialState = {
  primaryColor:"#E7424C",
  secondaryColor:"#ffffff",
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRIMARY_COLOR:
      return {
        ...state,
        primaryColor: action.payload,
      };
    case SET_SECONDARY_COLOR:
      return {
        ...state,
        secondaryColor: action.payload,
      };
    default:
      return state;
  }
};

export default themeReducer;
