import React,{useState,useEffect} from "react";
import userReservationStyles from "../../../../assests/styles/views/public/userReservation";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { Box, CircularProgress } from "@material-ui/core";
import { getLanguage } from "../../../../actions";

const UserReservationLayout = ({ children,classes }) => {
  const [loadingTranslation, setLoadingTranslation] = useState(true)
  
  useEffect(() => {
    getUpdatedTranslation();
  }, [])
  const getUpdatedTranslation = () =>{ 
    if(localStorage.getItem("lang")===null){
      localStorage.setItem("lang","fr")
    }
    getLanguage(
      resp => {
        if(resp?.data){
          localStorage.setItem("dictionary", JSON.stringify(resp.data));
          setLoadingTranslation(false);
        }
        
      },
      error => {

      }
    )
  }
  return  loadingTranslation ? <Box display= "flex"
  alignItems= "center"
  justifyContent= "center"
  flexDirection= "column"> 
  <CircularProgress
    style={{ margin: "auto" }}
  /></Box> : (
    <div className={classes.layoutWrapper}>
      <Navbar classes={classes} />
      <div className={classes.main}>{children}</div>
      <Footer classes={classes} />
    </div>
  );
};

export default UserReservationLayout;
