import { MyDialog } from "../../../components/Dialog";
import { getTranslation } from "../../../heplers/translationHelper";
import { ReservationForm } from "../../public/component/ReservationForm";

const EditReservationModal = ({
  openModal,
  onClose,
  isEdit,
  selectedReservation,
  params,
  setParams,
  refreshReservations
  
}) => {
  return (
    <MyDialog
      open={openModal}
      onClose={onClose}
      title={isEdit ? getTranslation(
        "Edit Reservation",
        "Edit Reservation",
        "Edit Reservation"
      ) : getTranslation(
        "Add Reservation",
        "Add Reservation",
        "Add Reservation"
      )}
    >
      <div>
        <ReservationForm
          isEdit={isEdit}
          selectedData={{...selectedReservation,oldPersons:selectedReservation.persons}}
          onCloseDialog={onClose}
          params={params}
          setParams={setParams}
          refreshReservations={() => refreshReservations()}

        />
      </div>
    </MyDialog>
  );
};

export default EditReservationModal;
