import { Box, Card, CardContent, makeStyles, useMediaQuery } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import moment from "moment";
import React from "react";
import { StyledToggleButtonGroup } from "../../../../components";
import { daysConstant } from "../../../../constants/general";
import { getTranslation } from "../../../../heplers/translationHelper";
import styles from "./timeSlotStyles.module.css";


const useStyles = makeStyles((theme) => ({
  buttonCont: {
    "& :hover": {
      backgroundColor: "#e7424c",
      color:"white"
    },
  },
}));

const DaysTabs = ({ params, setParams }) => {
  // const days = [
    
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  // ];


  const handleChange = (val) => {
    let date = moment("22-01-2023","DD-MM-YYYY").add(val,'days');
    const selectedDate = date.format("MM-DD-YYYY");
    setParams({ ...params, day: val, selectedDate });
  };
  const classes = useStyles();
  const isMobile=useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Card style={{marginTop:"20px",marginBottom:'20px',overflow:"auto"}}>
      <Box display="flex" justifyContent={isMobile?"left":"center"}>
      <StyledToggleButtonGroup value={params.day}>
        {daysConstant.map((day, index) => (
          <ToggleButton
            className={classes.buttonCont}
            color="primary"
            onClick={() => {
              if (day)
                handleChange(day.id)
            }}
            value={day.id}
          // className={params.day === index && styles.active}
          >
            { getTranslation(day.title,day.title,day.title)}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Box>
    </Card>
    

    
  );
};

export default DaysTabs;
