import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import userReservationStyles from "../../../../assests/styles/views/public/userReservation";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../heplers/translationHelper";
import * as Yup from "yup";
import { Post_Reservation_URL } from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useState } from "react";

const PersonalInfoForm = ({
  selectedReservation,
  setSelectedReservation,
  addReservation,
  setActiveStep,
  showSuccessMsg,
  setShowSuccessMsg,
  classes
}) => {
  // const classes = userReservationStyles();
  const params = useParams();
  let { primaryColor, secondaryColor } = params;
  if (typeof primaryColor === 'undefined')
    primaryColor = "E7424C"
  if (typeof secondaryColor === 'undefined')
    secondaryColor = "ffffff"
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    // allergies: Yup.string(),
    //haveSmallKids: Yup.boolean(),
    acceptTerms: Yup.boolean().required("Accept terms is required to proceed"),
  });

  const onSubmit = (values, actions) => {
    if (values.haveSmallKids && values.kids < 1) {
      actions.setSubmitting(false);
      enqueueSnackbar(
        getTranslation(
          "Minimum 1 kid required",
          "Minimum 1 kid required",
          "Minimum 1 kid required"
        ),
        {
          variant: "error",
        }
      );
      return;
    }

    const data = { ...selectedReservation, ...values };

    setSelectedReservation(data);
    const { title, acceptTerms, startTime, ...rest } = data;

    const payload = {
      allergies: rest.allergies,
      haveSmallKids: rest.haveSmallKids,
      kids: rest.kids,
      persons: rest.persons,
      timeSlotId: rest.timeSlotId,
      timeSlots: rest.timeSlots,
      reservationDate: moment(data.reservationDate).format("MM-DD-YYYY"),
      purposeOfReservation: data.purposeOfReservation,
      customer: {
        fullName: rest.fullName,
        email: rest.email,
        phone: rest.phone,
        subscribeForNews: rest.subscribeForNews,
      },
      primaryColor: primaryColor,
      secondaryColor: secondaryColor
    };
    addReservation(payload, Post_Reservation_URL, actions);
  };

  const handleBack = (val) => {
    const modifiedValue = { ...val, title: val.fullName };
    const data = { ...selectedReservation, ...modifiedValue };
    setSelectedReservation(data);
    setActiveStep(0);
  };

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          ...selectedReservation,
          fullName: selectedReservation.title,
        }}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.inputConts}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label={getTranslation("Full Name", "Full Name", "Full Name")}
                name="fullName"
                type="text"
                size="small"
                value={values.fullName}
                onChange={handleChange}
                error={touched.fullName && Boolean(errors.fullName)}
                helperText={touched.fullName && errors.fullName}
                inputProps={{
                  pattern: '^[A-Za-z\\s]+$',
                  onKeyPress: (event) => {
                    const charCode = event.charCode;
                    if (!/[A-Za-z\s]/.test(String.fromCharCode(charCode))) {
                      event.preventDefault();
                    }
                  },
                }}
              />
              <TextField
                variant="outlined"
                required
                fullWidth
                label={getTranslation("Email", "Email", "Email")}
                name="email"
                type="string"
                size="small"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </div>
            <TextField
              className={classes.input}
              variant="outlined"
              required
              fullWidth
              label={getTranslation(
                "Phone Number",
                "Phone Number",
                "Phone Number"
              )}
              name="phone"
              type="number"
              size="small"
              value={values.phone}
              onChange={handleChange}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
            />
            <TextField
              className={classes.input}
              variant="outlined"
              multiline
              minRows={5}
              fullWidth
              label={getTranslation(
                "Give information of allergies and Intolerance",
                "Give information of allergies and Intolerance",
                "Give information of allergies and Intolerance"
              )}
              name="allergies"
              type="number"
              size="small"
              value={values.allergies}
              onChange={handleChange}
              error={touched.allergies && Boolean(errors.allergies)}
              helperText={touched.allergies && errors.allergies}
            />
            <div>
              <FormControlLabel
                label={getTranslation("Select if there will be kids < 4 years old", "Select if there will be kids < 4 years old", "Select if there will be kids < 4 years old")}
                name="haveSmallKids"
                value={values.haveSmallKids}
                onChange={(val) => {
                  const { checked } = val.target;
                  setFieldValue("haveSmallKids", checked);
                  if (checked == false) {
                    setFieldValue("kids", 0);
                  }

                }}
                control={
                  <Checkbox
                    checked={values.haveSmallKids}
                    // className={classes.checkBox}
                    style={{ color: "#" + primaryColor }}
                  />
                }
              />
            </div>
            {values.haveSmallKids && (
              <TextField
                variant="outlined"
                required
                fullWidth
                label={getTranslation(
                  "Number of Kids",
                  "Number of Kids",
                  "Number of Kids"
                )}
                name="kids"
                type="number"
                size="small"
                value={values.kids}
                onChange={handleChange}
                error={touched.kids && Boolean(errors.kids)}
                helperText={touched.kids && errors.kids}
                InputProps={{ inputProps: { min: 1 } }}
              />
            )}
            <div>
              <FormControlLabel
                label={getTranslation("Accept Terms & Conditions", "Accept Terms & Conditions", "Accept Terms & Conditions")}
                name="acceptTerms"
                value={values.acceptTerms}
                onChange={(val) => {
                  const { checked } = val.target;
                  setFieldValue("acceptTerms", checked);
                }}
                control={
                  <Checkbox
                    checked={values.acceptTerms}
                    style={{ color: "#" + primaryColor }}

                  />

                }
              />
              {touched.acceptTerms && errors.acceptTerms ? (
                <div>{errors.acceptTerms}</div>
              ) : null}
            </div>
            <div>
              <FormControlLabel
                label={getTranslation(
                  "Subscribe for Newsletter",
                  "Subscribe for Newsletter",
                  "Subscribe for Newsletter"
                )}
                value={values.subscribeForNews}
                name="subscribeForNews"
                onChange={(val) => {
                  const { checked } = val.target;
                  setFieldValue("subscribeForNews", checked);
                }}
                control={
                  <Checkbox
                    checked={values.subscribeForNews}
                    style={{ color: "#" + primaryColor }}

                  />
                }
              />
            </div>
            <div className={classes.btnWrapper}>
              <ButtonWithLoading
                className={classes.submitBtn}
                type="button"
                onClick={() => handleBack(values)}
                title={getTranslation("Back", "Back", "Back")}
                variant="contained"
                style={{
                  //backgroundColor:"#"+primaryColor,
                  color: "black"
                }}
              />
              <ButtonWithLoading
                type="submit"
                disabled={!values.acceptTerms}
                onClick={handleSubmit}
                title={getTranslation("Submit", "Submit", "Submit")}
                variant="contained"
                loading={isSubmitting}
                // color={"primary"}
                style={values.acceptTerms == false ? {
                  marginLeft: "50px",

                } :
                  isSubmitting == false ? {
                    marginLeft: "50px",
                    backgroundColor: "#" + primaryColor,
                    color: "#" + secondaryColor
                  } : {
                    marginLeft: "50px",
                    backgroundColor: "gray",
                    color: "#" + primaryColor
                  }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfoForm;
