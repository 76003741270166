/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import userReservationStyles from "../../../assests/styles/views/public/userReservation";
import { getTranslation } from "../../../heplers/translationHelper";
import authService from "../../../utils/authUtils";
import LanguageSelector from "../component/languageSelector";
import UserReservationLayout from "../component/Layout";
import Navbar from "../component/Navbar";
import { ReservationForm } from "../component/ReservationForm";
import { Get_Reservation_URL, Get_Reservations_URL } from "../../../constants/apiUrls";
import { useState } from "react";
import { Get } from "../../../actions";

const UserReservation = () => {
  const params = useParams();
  const { customerId,primaryColor,secondaryColor,reservationId } = params;
  const setCustomerId = authService.setCustomerId;
  const [selectedReservation, setSelectedReservaton]= useState();
  const [loading, setLoading]= useState(false);


  useEffect(() => {
    if(localStorage.getItem("customerId") != null && localStorage.getItem("token") != null)
  {
    authService.logout();
    window.location.reload();
  }
  else
  setCustomerId(customerId)
}, []);
  useEffect(()=>{
    if(reservationId){
    setLoading(true);
    
    Get(
      {
        id:reservationId
      },
      Get_Reservation_URL,
      null,
      (res)=>{
        res.data.oldPersons=res?.data.persons
        setSelectedReservaton(res.data);
        setLoading(false);
      },
      (err)=>{
        setLoading(false);
      }

    )}
  },[reservationId])
  const classes = userReservationStyles({ primaryColor : "#"+primaryColor,secondaryColor : "#"+secondaryColor});


  return (
    <>
      <UserReservationLayout classes={classes}>
        {/* <div className={classes.titleCont}>
          <h1 className={classes.title}>Heading</h1>
          <span className={classes.titleDescription}>
            {getTranslation(
              "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal            distribution of letters",
              "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal            distribution of letters",
              "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal            distribution of letters"
            )}
          </span>
        </div> */}
        <div className={classes.formWrapper}>
          <ReservationForm classes={classes} isEdit={selectedReservation} selectedData={selectedReservation} refreshReservations={()=>window.location.reload() } />
        </div>
      </UserReservationLayout>
    </>
  );
};

export default UserReservation;
