import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Get } from "../../actions";
import { useReservationStyles } from "../../assests/styles/views/Reservations/reservationStyles";
import {
  Get_All_Print_Reservation_URL,
  Get_Reservations_URL,
} from "../../constants/apiUrls";
import { SET_LASTUPDATEOFRESERVATIONS } from "../../constants/types";
import Filtration from "./components/filtration";
import ReservationNavTabs from "./components/navTabs";
import Progressbar from "./components/progressbar";
import ReservationTable from "./components/Table";
import { DaySelection } from "./components/daySelection";
import { Helmet } from "react-helmet";
import { getTranslation } from "../../heplers/translationHelper";

const CurrentReservation = () => {
  const classes = useReservationStyles();
  const [queryParams, setQueryParams] = useState({
    selectedDayTime: 0,
    selectedDate: new Date(),
    pageNumber: 0,
    pageSize: 10,
    totalCount: 1,
    searchString: "",
  });
  const [isLoadingReservation, setIsLoadingReservation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [stats, setStats] = useState({
    maxCapacity: 0,
    numberOfGroups: 0,
    numberOfPersons: 0,
    percentage: "0%",
  });
  const [printData, setPrintData] = useState([]);
  const [reservations, setReservations] = useState([]);

  const getReservations = (payload) => {
    setIsLoadingReservation(true);
    Get(
      payload,
      Get_Reservations_URL,
      null,
      (res) => {
        const statsData = {
          maxCapacity: res?.data.maxCapacity,
          numberOfGroups: res?.data.numberOfGroups,
          numberOfPersons: res?.data.numberOfPersons,
          percentage: res?.data.percentage,
        };
        //  numberOfPersons={statsData?.numberOfPersons}/>
        setIsLoadingReservation(false);
        setReservations(res?.data?.data);
        setStats(statsData);
      },
      (err) => {
        setIsLoadingReservation(false);
        enqueueSnackbar(err?.data, {
          variant: "error",
        });
      }
    );
  };

  const getAllPrintReservations = (payload) => {
    setIsLoadingReservation(true);
    Get(
      payload,
      Get_All_Print_Reservation_URL,
      null,
      (res) => {
        setIsLoadingReservation(false);
        setPrintData(res?.data);
      },
      (err) => {
        setIsLoadingReservation(false);
        enqueueSnackbar(err.data, {
          variant: "error",
        });
      }
    );
  };

  useEffect(() => {
    getReservations({
      date: queryParams.selectedDate,
      dayTime: queryParams.selectedDayTime,
      pageNumber: queryParams.pageNumber,
      pageSize: queryParams.pageSize,
    });
    getAllPrintReservations({
      date: queryParams.selectedDate,
      dayTime: queryParams.selectedDayTime,
    });
  }, [queryParams]);

  const dispatch = useDispatch();

  return (

    <div>
      <Helmet>
        <title>
          {window.location.pathname == "/" || window.location.pathname == "/dashboard" ?
            getTranslation("Dashboard", "Dashboard", "Dashboard")
            :
            getTranslation("Reservations", "Reservations", "Reservations")}
        </title>
      </Helmet>

      <div className={classes.container}>
        <Filtration params={queryParams} setParams={setQueryParams} />
      </div>
      <div className={classes.container}>
        <Progressbar stats={stats} />
      </div>
      <div className={classes.container}>
        <ReservationTable
          reservations={reservations}
          params={queryParams}
          setParams={setQueryParams}
          loading={isLoadingReservation}
          stats={stats}
          printData={printData}
          refreshReservations={() => {
            setQueryParams({ ...queryParams })
            dispatch({ type: SET_LASTUPDATEOFRESERVATIONS, payload: new Date() });
          }}
        />
      </div>
    </div>
  );
};

export default CurrentReservation;
