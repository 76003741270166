import { CardContent, makeStyles } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import CardComponent from "../../../components/Card";
import { getTranslation } from "../../../heplers/translationHelper";

const useStyles = makeStyles((theme) => ({
  percent: {
    color: theme.palette.primary.main,
    fontWeight: "600",
    paddingTop: "6PX",
  },
  bar: {
    color: "#000000",
    paddingTop: "5PX",
    paddingLeft: "15PX",
    fontSize: "18px",
  },
}));

const Progressbar = ({ stats }) => {
  const classes = useStyles();
  const { maxCapacity, numberOfGroups, numberOfPersons, percentage } = stats;

  const newPercent = maxCapacity ? (numberOfPersons / maxCapacity) * 100 : 0;

  return (
    <CardComponent>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Typography variant="h1" className={classes.percent} style={{marginLeft:"10px"}}>
              {`${newPercent.toFixed(2)}%`}
            </Typography>
          </Grid>
          {/* <Grid item spacing={4}> */}
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Typography variant="h4" className={classes.bar}>
              {getTranslation("Total Capacity", "Total Capacity", "Total Capacity")}
              <br />
              {maxCapacity}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Typography variant="h4" className={classes.bar}>
              {getTranslation("Number of Persons", "Number of Persons", "Number of Persons")}

              <br />
              {numberOfPersons}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Typography variant="h4" className={classes.bar}>
              {getTranslation("Number of Groups", "Number of Groups", "Number of Groups")}

              <br />
              {numberOfGroups}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </CardComponent>
  );
};

export default Progressbar;
