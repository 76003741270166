/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  Divider,
  Modal,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Get } from "../../../../actions";
import { getTimeSlot } from "../../../../actions/userReservationActions";
import userReservationStyles from "../../../../assests/styles/views/public/userReservation";
import {
  Get_GetAvailableTimeSlots_URL,
  Get_ResturentCapacityStatus_URL,
  Get_TimeSlotsById_URL,
  Get_VerifyUpdateReservation_URL,
} from "../../../../constants/apiUrls";
import { format } from "../../../../heplers/format";
import { getTranslation } from "../../../../heplers/translationHelper";
import RestoCapacityDialog from "./restoCapacityDialog";

const TimeCard = ({
  id,
  startTime,
  endTime,
  selectedId,
  handleTimeClick,
  classes,
  DurationOfTimeSlot,
  disable = false,
}) => {
  return (
    <>
      <button
        type="button"
        className={selectedId === id ? classes.btnIsSelected : classes.timeBtn}
        onClick={() => handleTimeClick(id, startTime)}
        disabled={disable}
      >
        <Typography
          style={{
            textDecoration: disable ? "line-through" : "",
          }}
        >
          {startTime}
          <Divider />
          {endTime}
        </Typography>
      </button>
    </>
  );
};

const TimeSlots = memo(
  ({
    isEdit,
    formData,
    setTimeSlotId,
    selectedReservation,
    setSelectedReservation,
    setVerifcationStatus,
    classes,
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
    const [isBusy, setIsBusy] = useState(false);
    const [isOpenCapacityDialog, setIsOpenCapacityDialog] = useState(false);
    const [selectedPerons, setSelectedPerons] = useState(false);
    const getAvailableTimeSlots = (payload, persons) => {
      setIsBusy(true);
      Get(
        { date: payload, persons: persons },
        Get_GetAvailableTimeSlots_URL,
        null,
        (resp) => {
          setAvailableTimeSlots(resp.data);
          //  if(resp.data.length<1){
          //   setIsOpenCapacityDialog(true)
          //  }
          setIsBusy(false);
        },
        (error) => {
          enqueueSnackbar(error.data, { variant: "error" });
          setAvailableTimeSlots([]);
          setIsBusy(false);
        }
      );
    };

    const getTimeSlotById = (payload) => {
      Get(
        { id: payload },
        Get_TimeSlotsById_URL,
        null,
        (res) => {
          setSelectedTimeSlot(res.data);
        },
        (error) => {
          enqueueSnackbar("System was not able to handle request.", {
            variant: "error",
          });
        }
      );
    };

    const getResturentCapacityStatus = (date, persons, dayTime) => {
      Get(
        {
          date: date,
          persons: persons,
          dayTime: dayTime,
        },
        Get_ResturentCapacityStatus_URL,
        null,
        (resp) => {
          setSelectedPerons(persons);
          if (resp.data == "Capcity not available")
            setIsOpenCapacityDialog(true);
        },
        (error) => {}
      );
    };

    const getVerifyReservationStatus = (reservationId, date, persons) => {
      Get(
        {
          id: reservationId,
          date: date,
          persons: persons,
        },
        Get_VerifyUpdateReservation_URL,
        null,
        (resp) => {
          setVerifcationStatus(resp.data);
          if (resp.data == "Ok") {
            const arr = selectedReservation.timeSlots;
            const lastItem = arr[selectedReservation.timeSlots.length - 1];
            if (selectedReservation.oldPersons > formData.persons) {
              const newPersons =
                selectedReservation.oldPersons - formData.persons;
              lastItem.reservedPersons = lastItem.reservedPersons - newPersons;
            } else {
              const newPersons =
                formData.persons - selectedReservation.oldPersons;
              lastItem.reservedPersons = lastItem.reservedPersons + newPersons;
            }

            arr[selectedReservation.timeSlots.length - 1] = lastItem;
            setSelectedReservation({ ...selectedReservation, timeSlots: arr });
          } else {
            setSelectedReservation({ ...selectedReservation, timeSlots: [] });
          }
        },
        (error) => {}
      );
    };

    useEffect(() => {
      if (moment(formData?.reservationDate).isValid() && formData.persons > 0) {
        getAvailableTimeSlots(
          moment(formData?.reservationDate).format("MM-DD-YYYY"),
          formData.persons
        );

        getResturentCapacityStatus(
          moment(formData?.reservationDate).format("MM-DD-YYYY"),
          formData.persons,
          formData?.timeSlots[0]?.dayTime ? formData?.timeSlots[0]?.dayTime : 0
        );
        if (isEdit)
          getVerifyReservationStatus(
            formData?.id,
            moment(formData?.reservationDate).format("MM-DD-YYYY"),
            formData.persons
          );
      }
      if (selectedReservation?.timeSlotId && isEdit) {
        setTimeSlotId({
          ...formData,
          timeSlotId: selectedReservation?.timeSlotId,
        });
      }
    }, [
      formData?.reservationDate,
      formData?.persons,
      formData?.timeSlots[0]?.dayTime,
    ]);

    useEffect(() => {
      if (selectedReservation?.timeSlotId && isEdit) {
        getTimeSlotById(selectedReservation?.timeSlotId);
      }
    }, [selectedReservation]);

    const onTimeClick = (timeSlots, start) => {
      setTimeSlotId({
        ...formData,
        timeSlotId: timeSlots[0].id,
        startTime: timeSlots[0].start,
        timeSlots: timeSlots,
      });
      setSelectedReservation({ ...selectedReservation, timeSlots: timeSlots });
    };

    const allTimeSlots =
      selectedTimeSlot &&
      availableTimeSlots.findIndex((x) => x.id == selectedTimeSlot.id) == -1
        ? availableTimeSlots.concat(selectedTimeSlot)
        : availableTimeSlots;

    const dayTimeSlots = allTimeSlots?.filter((d) => d[0].dayTime === 0);
    const eveningTimeSlots = allTimeSlots?.filter((d) => d[0].dayTime === 1);

    return (
      <div className={classes.timeSlotCont}>
        {dayTimeSlots.length > 0 && isBusy == false ? (
          <div className={classes.timeSlotsWrapper}>
            {dayTimeSlots.map((dt, index) => {
              const startDate = new Date(dt[0].startDateTime); //moment(new Date(dt[0].startDateTime), "hh:mm:ss");
              let endDate = new Date(dt[0].endDateTime);
              if (dt.length == 2) endDate = new Date(dt[1].endDateTime);
              const DurationOfTimeSlot = moment
                .utc(
                  moment(endDate, "HH:mm:ss").diff(
                    moment(startDate, "HH:mm:ss")
                  )
                )
                .format("HH:mm");

              const startTime = format("time", startDate); //`${startDate.getHours()} : ${startDate.getMinutes()}`;
              const endTime = format("time", endDate); //`${startDate.getHours()} : ${startDate.getMinutes()}`;
              return (
                <TimeCard
                  key={dt[0].id}
                  id={dt[0].id}
                  startTime={startTime}
                  endTime={endTime}
                  DurationOfTimeSlot={DurationOfTimeSlot}
                  selectedId={formData.timeSlotId}
                  handleTimeClick={() => onTimeClick(dt)}
                  disable={dt[0].isAvailable == false}
                  classes={classes}
                />
              );
            })}
          </div>
        ) : isBusy ? (
          <CircularProgress />
        ) : (
          <Box className={classes.emptyTimeSlot}>
            {" "}
            <Typography variant="subtitle2">
              {getTranslation(
                " No Day-Time slot for the selected date.",
                " No Day-Time slot for the selected date.",
                " No Day-Time slot for the selected date."
              )}
            </Typography>
            <RestoCapacityDialog
              handleOpen={isOpenCapacityDialog}
              handleClose={() => setIsOpenCapacityDialog(false)}
            />
          </Box>
        )}
        <hr />
        {eveningTimeSlots.length > 0 && isBusy == false ? (
          <div className={classes.timeSlotsWrapper}>
            {eveningTimeSlots.map((dt, index) => {
              const startDate = new Date(dt[0].startDateTime); //moment(new Date(dt[0].startDateTime), "hh:mm:ss");
              let endDate = new Date(dt[0].endDateTime);
              if (dt.length == 2) endDate = new Date(dt[1].endDateTime);
              const DurationOfTimeSlot = moment
                .utc(
                  moment(endDate, "HH:mm:ss").diff(
                    moment(startDate, "HH:mm:ss")
                  )
                )
                .format("HH:mm");

              const startTime = format("time", startDate); //`${startDate.getHours()} : ${startDate.getMinutes()}`;
              const endTime = format("time", endDate); //`${startDate.getHours()} : ${startDate.getMinutes()}`;
              return (
                <TimeCard
                  key={dt[0].id}
                  id={dt[0].id}
                  startTime={startTime}
                  endTime={endTime}
                  selectedId={formData.timeSlotId}
                  DurationOfTimeSlot={DurationOfTimeSlot}
                  handleTimeClick={() => onTimeClick(dt)}
                  disable={dt?.isAvailable == false}
                  classes={classes}
                />
              );
            })}
          </div>
        ) : isBusy ? (
          <CircularProgress />
        ) : (
          <Box className={classes.emptyTimeSlot}>
            {" "}
            <Typography variant="subtitle2">
              {getTranslation(
                "No Evening-time slot for the selected date.",
                "No Evening-time slot for the selected date.",
                "No Evening-time slot for the selected date."
              )}
            </Typography>
          </Box>
        )}
        <RestoCapacityDialog
          handleOpen={isOpenCapacityDialog}
          handleClose={() => setIsOpenCapacityDialog(false)}
          selectedPerons={selectedPerons}
        />
      </div>
    );
  }
);

export default TimeSlots;
