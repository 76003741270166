import * as React from "react";

const ReservationIcon = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={32} height={32} rx={16} fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.352 5.818H10.648a.815.815 0 0 0-.63.324 1.25 1.25 0 0 0-.262.78v17.67c0 .294.094.575.261.782a.815.815 0 0 0 .63.323h10.705a.815.815 0 0 0 .63-.323 1.25 1.25 0 0 0 .262-.781V6.923a1.25 1.25 0 0 0-.262-.781.815.815 0 0 0-.63-.324ZM18.676 21.28h-5.352v-2.21h5.352v2.21Zm0-4.418h-5.352v-2.209h5.352v2.209Zm0-4.417h-5.352v-2.21h5.352v2.21Z"
      fill="#E7424C"
      fillOpacity={0.71}
    />
  </svg>
);

export default ReservationIcon;
