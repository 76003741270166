import React from "react";
import userReservationStyles from "../../../../assests/styles/views/public/userReservation";
import { Logo, Logo1, } from "../../../../components/Logo";

const Footer = ({classes}) => {
  return (
    <div className={classes.footer}>
      <div className={classes.footerLogoWrapper}>
        <Logo className={classes.img} />
      </div>
    </div>
  );
};

export default Footer;
