import {colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#E7424C",
      light: "#f33333",
      dark: "#CC6E00",
      contrastText: "#ffffff",
     
    },
    secondary: {
      main: "#ffffff",
      dark: "#FF8A00",
      light: "#f33333",
      contrastText: "#000000",
    },
    text: {
      primary: colors.blueGrey[600],
      secondary: colors.blueGrey[600],
    },
    neutral: {
      main: '#F4F6F8',
      contrastText: '#fff',
    },
  },
  shadows,
  typography,
  
});

theme.props = {
  MuiButton: {
    disableElevation:true,
  },
};

theme.overrides={
  MuiButton:{
    root:{
      textTransform:"none"
    }
  }
}

export default theme;
