import { Box, Grid } from "@material-ui/core"
import { useState } from "react"
import { MyDialog } from "../../components/Dialog"
import { getTranslation } from "../../heplers/translationHelper"
import authService from "../../utils/authUtils"

export const CustomIframeDialog=({open,onClose,onSubmit})=>{
    const [primaryColor,setPrimaryColor]=useState("E7424C");
    const [secondaryColor,setSecondaryColor]=useState("ffffff");
    return <MyDialog fullWidth maxWidth={"md"} title={getTranslation("Generate Iframe Code")}  open={open} onClose={onClose}>
        <>
            <Grid container spacing={5}>
                <Grid item>
                    <label>Primary Color: </label>
                    <input type="color" value={"#"+primaryColor} onChange={(e)=>setPrimaryColor(e.target.value.replace("#",""))} />
                    &nbsp;&nbsp;&nbsp;
                    <label>Secondary Color: </label>
                    <input type="color"  value={"#"+secondaryColor} onChange={(e)=>setSecondaryColor(e.target.value.replace("#",""))}/>
                </Grid>
                <Grid item>
                <label>Generated Code: </label>

                    <Box border={"1px solid gray"} p={1} minHeight="100px">
                        <pre>
                            {`<iframe src="https://${window.location.hostname+"/public/user-reservation/"+authService.getCustomerId()+"/"+primaryColor+"/"+secondaryColor}" width="500px" height="600px"></iframe>`}
                        </pre>
                    </Box>
                   
                </Grid>
                <Grid sm={12} md={12} lg={12} >
                    <label>Preview: </label>
                    <br />
                    <iframe src={"https://"+window.location.hostname+"/public/user-reservation/"+authService.getCustomerId()+"/"+primaryColor+"/"+secondaryColor}
                    width="100%"
                    height="500px"
                    ></iframe>
                </Grid>
            </Grid>

        </>
    </MyDialog>
}