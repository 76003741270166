import React, { useEffect, useState } from "react";
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  Box,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { Formik } from "formik";
import { Link, withRouter, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import useStyles from "../../assests/styles/views/auth/login";
import Copyright from "../../utils/copyRightUtils";
import { TransparentLogo } from "../../components/Logo";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import {
  userLogin,
  getCustomerApps,
  Post,
  verifyInternalAppLogin,
} from "../../actions";
import checkResponse from "../../heplers/responseHelper";
import { useSnackbar } from "notistack";
import authUtils from "../../utils/authUtils";
import authService from "../../utils/authUtils";
import { Post_VerifyInternalAppLogin_URL } from "../../constants/apiUrls";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getTranslation } from "../../heplers/translationHelper";

const Login = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [customerAppsLoading, setCustomerAppsLoading] = useState(false);
   const params = useParams();
  const [customerApps, setCustomerApps] = useState([]);
  const [lastLocation, setLastLocation] = useState("");
  const [showStep, setShowStep] = useState(false);
  const [isLoginInfoLoaded, setIsLoginInfoLoaded] = useState(false);

  useEffect(() => {
    const token = params.token;
    if (authService.isAuthenticated() || authService.isRefreshAuthenticated()) {
      history.push("/");
    } else if (token) {
      verifyInternalAppLogin(
        { token: token },
        (res) => {
          authService.logout();
          authUtils.setUser(res.data.appUser);
          authUtils.setSession(res.data.accessToken);
          authUtils.setUserRefreshToken(
            res.data.refreshToken,
            res.data.refreshTokenExpire
          );
          authUtils.setCustomerId(res.data.customerId);
          history.push("/");
          //actions.setSubmitting(false);
          enqueueSnackbar(
            getTranslation(
              " Welcome to the app ",
              " Bienvenue sur l'application ",
              " Willkommen in der App "
            ),
            { variant: "success" }
          );
        },
        (error) => {
          setIsLoginInfoLoaded(true);
          enqueueSnackbar(
            getTranslation(
              " Invalid Token ",
              " jeton invalide ",
              " Ung�ltiges Token "
            ),
            { variant: "error" }
          );
        }
      );
    } else {
      setIsLoginInfoLoaded(true);
    }
  }, []);
  let schema = Yup.string().email("Invalid");
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {isLoginInfoLoaded ? (
        <div className={classes.paper}>
          <div className={classes.logo}>
            <TransparentLogo />
          </div>
          <Typography component="h1" variant="h2" color="secondary">
            {getTranslation("Login", "Connexion", "Anmeldung")}
          </Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
              organizationName: "",
              appId: 4,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(
                getTranslation(
                  " Please enter a valid Email ",
                  " Veuillez saisir un e-mail valide ",
                  " Bitte geben Sie eine g�ltige Email-Adresse ein "
                )
              ),
              password: Yup.string().required(
                getTranslation(
                  " Password is required ",
                  " Mot de passe requis ",
                  " Passwort wird ben�tigt "
                )
              ),
            })}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              dispatch(userLogin(values)).then((res) => {
                const errors = checkResponse(res);
                if (!errors) {
                  authUtils.setUser(res.data.appUser);
                  authUtils.setSession(res.data.accessToken);
                  authUtils.setUserRefreshToken(
                    res.data.refreshToken,
                    res.data.refreshTokenExpire
                  );
                  authUtils.setCustomerId(values.customerId);
                  history.push("/");
                  actions.setSubmitting(false);
                } else {
                  actions.setSubmitting(false);
                  errors.forEach((error) => {
                    enqueueSnackbar(error.message, {
                      variant: "error",
                    });
                  });
                }
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              validateForm,
              setFieldValue,
              isSubmitting,
            }) => (
              <form className={classes.form}>
                {!showStep ? (
                  <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={getTranslation(
                        "Email Address",
                        "Adresse e-mail",
                        "E-Mail-Addresse"
                      )}
                      name="email"
                      autoComplete="email"
                      autoFocus
                      size="small"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <Box display="flex" justifyContent="flex-end">
                      <ButtonWithLoading
                        loading={customerAppsLoading}
                        title={getTranslation("Next", "Suivant", "N�chste")}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          validateForm(values).then((verrors) => {
                            if (verrors.email) {
                              enqueueSnackbar(
                                getTranslation(
                                  " Provide a valid email ",
                                  " Fournir un email valide ",
                                  " Geben Sie eine g�ltige E-Mail-Adresse an "
                                ),
                                {
                                  variant: "error",
                                }
                              );
                            } else {
                              const payload = {
                                email: values.email,
                                appId: 4,
                              };
                              //loadCustomerApps(payload);
                              getCustomerApps(
                                payload,
                                (resp) => {
                                  if (resp?.length === 0) {
                                    enqueueSnackbar(
                                      getTranslation(
                                        " No customer apps exists ",
                                        " Aucune application client n'existe ",
                                        " Es sind keine Kunden-Apps vorhanden "
                                      ),
                                      {
                                        variant: "error",
                                      }
                                    );
                                    setCustomerAppsLoading(false);
                                  } else {
                                    setCustomerApps(resp);
                                    setCustomerAppsLoading(false);
                                    setShowStep(true);
                                  }
                                },
                                (error) => {
                                  setCustomerAppsLoading(false);
                                  enqueueSnackbar(error.data, {
                                    variant: "error",
                                  });
                                }
                              );
                            }
                          });
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="demo-simple-select-label">
                        {getTranslation(
                          " Select organization ",
                          " S�lectionnez l'organisation ",
                          " Organisation ausw�hlen "
                        )}
                      </InputLabel>
                      <Select
                        style={{
                          height: "50px",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={getTranslation(
                          "Select Organization",
                          "S�lectionnez l'organisation",
                          "W�hlen Sie Organisation aus"
                        )}
                        onChange={(e) => {
                          setFieldValue("customerId", e.target.value);
                        }}
                      >
                        {customerApps?.map((app) => (
                          <MenuItem value={app.customerId}>
                            {app.organizationName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      size="small"
                      name="password"
                      label={getTranslation(
                        "Password",
                        "Mot de passe",
                        "Kennwort"
                      )}
                      type="password"
                      id="password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />

                    <ButtonWithLoading
                      color="primary"
                      type="submit"
                      title={getTranslation("Login", "Connexion", "Anmeldung")}
                      fullWidth
                      variant="contained"
                      loading={isSubmitting}
                      onClick={handleSubmit}
                      className={classes.submit}
                    />

                    <Grid container>
                      <Grid item xs>
                        <Link
                          to="/forget_password"
                          color="secondary"
                          variant="body1"
                        >
                          {/* <Typography color="textSecondary">
                            {getTranslation(
                              "Forget Password",
                              "Mot de passe oubli�",
                              "Passwort vergessen"
                            )}
                          </Typography> */}
                        </Link>
                      </Grid>
                    </Grid>
                    <Box mt={3}>
                      <Copyright />
                    </Box>
                  </>
                )}
              </form>
            )}
          </Formik>
        </div>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Container>
  );
};

export default withRouter(Login);
