import { Box, Grid, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import { MyDialog } from "../../../../components/Dialog";
import { getTranslation } from "../../../../heplers/translationHelper";
import * as Yup from "yup";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useSnackbar } from "notistack";
import { Post } from "../../../../actions";
import { Upsert_MaxCapacity_URL } from "../../../../constants/apiUrls";
// import { useClickAway } from "react-use";

export const AddTable = ({
  open,
  handleClose,
  editTable,
  tableData,
  params,
  setParams,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    persons: Yup.number().min(1, "Value should be greater than 0"),
    quantity: Yup.number().min(1, "Value should begreater than 0"),
  });

  const onSubmit = (data, actions) => {
    actions.setSubmitting(true);
    if (data.id === null) delete data.id;
    Post(
      {...data, dayTime: params.dayTime},
      Upsert_MaxCapacity_URL,
      null,
      (res) => {
        actions.setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Updated Successfully",
            "Updated Successfully",
            "Updated Successfully"
          ),
          {
            variant: "success",
          }
        );
        handleClose(false);
        setParams({ ...params, dayTime: params.dayTime});
      },
      (err) => {
        if (!err) return;
        actions.setSubmitting(false);
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
                "Something went wrong",
                "Something went wrong",
                "Something went wrong"
              ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  return (
    <MyDialog
      open={open}
      title={
        editTable
          ? getTranslation("Edit Table", "Edit Table", "Edit Table")
          : getTranslation("Add Table", "Add Table", "Add Table")
      }
      onClose={() => handleClose(false)}
    >
      <div style={{ padding: "20px" }}>
        <Formik
          initialValues={tableData}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="type"
                    label={getTranslation(
                      "Table Type",
                      "Table Type",
                      "Table Type"
                    )}
                    name="persons"
                    type="number"
                    size="small"
                    value={values.persons}
                    onChange={handleChange}
                    error={touched.persons && Boolean(errors.type)}
                    helperText={touched.persons && errors.persons}
                    InputProps={{ inputProps: { min: 0} }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="quantity"
                    label={getTranslation("Quantity", "Quantity", "Quantity")}
                    name="quantity"
                    type="number"
                    size="small"
                    value={values.quantity}
                    onChange={handleChange}
                    error={touched.quantity && Boolean(errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                    InputProps={{ inputProps: { min: 0} }}
                  />
                </Grid>
              </Grid>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                pt={8}
                mt={2}
              >
                <ButtonWithLoading
                  title={
                    editTable
                      ? getTranslation("Update", "Update", "Update")
                      : getTranslation("Add", "Add", "Add")
                  }
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </MyDialog>
  );
};
