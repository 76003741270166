import React, { Suspense, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
// import SplashScreen from "./components/SplashScreen"
import Auth from "./components/Auth";
import AuthGuard from "./components/AuthGuard";
//Website Layouts
import AppLayout from "./layout";

//Auth Views
import Login from "./views/auth/Login";
import ForgetPassword from "./views/auth/ForgetPassword";

//Account Views
import MyAccount from "./views/account/myAccount";
// import { ReservationsList } from "./views/Reservations";
import Reservations from "./views/Reservations";
// import UserReservation from "./views/public/userReservation";
import CurrentReservation from "./views/Reservations/currentReservation";
import { UserReservation } from "./views/public";
import CustomerManagement from "./views/CustomerManagement";
import { TableManagement } from "./views/settings";
import { EditReservation } from "./views/public/editReservation";

const routesConfig = [
  {
    path: "/login",
    exact: true,
    component: () => <Login />,
  },
  {
    path: "/login/:token",
    exact: true,
    component: () => <Login autologin />,
  },
  {
    path: "/forget_password",
    exact: true,
    component: () => <ForgetPassword />,
  },
  {
    path: "/public/user-reservation/:customerId/:primaryColor/:secondaryColor",
    exact: true,
    component: () => <UserReservation />,
  },
  {
    path: "/public/user-reservation/:customerId/:primaryColor/:secondaryColor/:reservationId",
    exact: true,
    component:()=> <UserReservation/>
  },
  {
    path: "/",
    layout: AppLayout,
    guard: Auth,
    // bootstrap: AuthGuard,
    childrens: [
      {
        path: "/",
        exact: true,
        component: () => <CurrentReservation />,
      },
      {
        path: "/account/profile",
        exact: true,
        component: () => <MyAccount />,
      },
      {
        path: "/settings/table-management",
        exact: true,
        component: () => <TableManagement />,
      },
      {
        path: "/settings/table-management/:tab",
        exact: true,
        component: () => <TableManagement />,
      },
      {
        path: "/dashboard",
        exact: true,
        component: () => <CurrentReservation />,
      },
      {
        path: "/reservations",
        exact: true,
        component: () => <Reservations />,
      },
      {
        path: "/reservations/:tab",
        exact: true,
        component: () => <Reservations />,
      },

      {
        path: "/customers",
        exact: true,
        component: () => <CustomerManagement />,
      },

      // {
      //   path: "/settings",
      //   exact: true,
      //   component: () => <ReservationsSettings />,
      // },
      // {
      //   path: "/settings/table",
      //   exact: true,
      //   component: () => <ReservationsSettings />,
      // },

      {
        component: () => <div>Not Found</div>,
      },
    ],
  },

  {
    component: () => <div>Not Found</div>,
  },
];

const renderRoutes = (routes) => {
  return routes ? (
    <Suspense fallback={<h1>loading...</h1>}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const Bootstrap = route.bootstrap || Fragment;

          return (
            <Route
              exact={route.exact}
              path={route.path}
              key={i}
              render={(props) => (
                <Layout>
                  <Guard>
                    <Bootstrap>
                      {route.childrens ? (
                        renderRoutes(route.childrens)
                      ) : (
                        <Component {...props} />
                      )}
                    </Bootstrap>
                  </Guard>
                </Layout>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;
};

const AppRoutes = () => {
  return renderRoutes(routesConfig);
};

export default AppRoutes;
