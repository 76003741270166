import { Formik } from 'formik';
import React, { useState, useEffect } from 'react'
import { MyDialog } from '../../../components/Dialog';
import { getTranslation } from '../../../heplers/translationHelper';
import * as Yup from "yup";
import { Box, Button, Grid, TextField } from '@material-ui/core';
import ButtonWithLoading from '../../../components/ButtonWithLoading';
import { useSnackbar } from 'notistack';
import { Post } from '../../../actions';
import { Close_Service_URL, Post_CloseService_URL } from '../../../constants/apiUrls';
import axios from 'axios';

const CloseServiceModal = ({ openDialog, handleClose, serciveclosedBy }) => {

  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    persons: 0,
    groups: 0,
    eating: 0,
    drinking: 0,
    closeBy: serciveclosedBy?serciveclosedBy:"Today"
  }
  const [formData, setFormData] = useState(initialValues);
  const validationSchema = Yup.object().shape({
    persons: Yup.number().min(1,  getTranslation(
      "Please enter at least one value",
      "Please enter at least one value",
      "Please enter at least one value"
    )),
    groups: Yup.number().min(1,getTranslation(
      "Please enter at least one value",
      "Please enter at least one value",
      "Please enter at least one value"
    )),
    eating: Yup.number().min(1,getTranslation(
      "Please enter at least one value",
      "Please enter at least one value",
      "Please enter at least one value"
    )),
    drinking: Yup.number().min(1,getTranslation(
      "Please enter at least one value",
      "Please enter at least one value",
      "Please enter at least one value"
    )),
  })

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    Post(
      values,
      Post_CloseService_URL,
      null,
      (res) => {
        actions.setSubmitting(false);
        enqueueSnackbar(
          getTranslation(
            "Closed Successfully!",
            "Closed Successfully!",
            "Closed Successfully!"
          ),
          {
            variant: "success",
          }
        );
        setFormData(initialValues);
        handleClose(false);
      },
      (err) => {
        if (!err) return;
        actions.setSubmitting(false);
        // handleClose(false);
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
              "Something went wrong",
              "Something went wrong",
              "Something went wrong"
            ),
          {
            variant: "error",
          }
        );
      }
    );
    // handleClose(false);
  };

  return (
    <MyDialog
      open={openDialog}
      onClose={() => handleClose(false)}
      title={
        serciveclosedBy == "Yesterday"
          ? getTranslation(
              "Closing Service For Yesterday",
              "Closing Service For Yesterday",
              "Closing Service For Yesterday"
            )
          : getTranslation(
              "Closing Service",
              "Closing Service",
              "Closing Service"
            )
      }
    >
      <Box marginTop={"1rem"} marginBottom={"1rem"}>
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={12} lg={6}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    id="persons"
                    label={getTranslation(
                      "Number of persons",
                      "Number of persons",
                      "Number of persons"
                    )}
                    name="persons"
                    type="number"
                    size="small"
                    value={values.persons}
                    onChange={handleChange}
                    error={touched.persons && Boolean(errors.persons)}
                    helperText={touched.persons && errors.persons}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={6}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    id="groups"
                    label={getTranslation(
                      "Number of Groups",
                      "Number of Groups",
                      "Number of Groups"
                    )}
                    name="groups"
                    type="number"
                    size="small"
                    value={values.groups}
                    onChange={handleChange}
                    error={touched.groups && Boolean(errors.groups)}
                    helperText={touched.groups && errors.groups}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="eating"
                    label={getTranslation("Eating", "Eating", "Eating")}
                    name="eating"
                    type="number"
                    size="small"
                    value={values.eating}
                    onChange={handleChange}
                    error={touched.eating && Boolean(errors.eating)}
                    helperText={touched.eating && errors.eating}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="drinking"
                    label={getTranslation("Drinking", "Drinking", "Drinking")}
                    name="drinking"
                    type="number"
                    size="small"
                    value={values.drinking}
                    onChange={handleChange}
                    error={touched.drinking && Boolean(errors.drinking)}
                    helperText={touched.drinking && errors.drinking}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <ButtonWithLoading
                  title={getTranslation("Submit", "Submit", "Submit")}
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                />
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </MyDialog>
  );
}

export default CloseServiceModal