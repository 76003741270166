import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { MyDialog } from "../../../../components/Dialog";
import { getTranslation } from "../../../../heplers/translationHelper";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { useReservationStyles } from "../../../../assests/styles/views/Reservations/reservationStyles";
import { DatePicker } from "../../../../components/DatePicker";

const DateRangeSelectorModal = ({ openModal, onClose, params, setParams }) => {
  const classes = useReservationStyles();

  const onSubmit = (values) => {
    setParams({
      ...params,
      from: new Date(values.from),
      to: new Date(values.to),
    });
    onClose(false);
  };

  return (
    <MyDialog
      open={openModal}
      onClose={() => onClose(false)}
      title={getTranslation(
        "Select Date and Day",
        "Select Date and Day",
        "Select Date and Day"
      )}
    >
      <div>
        <Formik
          initialValues={{ from: params.from, to: params.to }}
          onSubmit={onSubmit}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <form>
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                  <DatePicker
                    className={classes.datePicker}
                    size="small"
                    label={getTranslation("From", "From", "From")}
                    value={values.from}
                    onChange={(val) => {
                      setFieldValue("from", val);
                    }}
                    id="from"
                    name="from"
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                  <DatePicker
                    className={classes.datePicker}
                    size="small"
                    label={getTranslation("To", "To", "To")}
                    value={values.to}
                    onChange={(val) => {
                      setFieldValue("to", val);
                    }}
                    id="to"
                    name="to"
                  />
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                  <div className={classes.btnWrapper}>
                    <ButtonWithLoading
                      type="submit"
                      onClick={handleSubmit}
                      title={getTranslation(
                        "Go to Date",
                        "Go to Date",
                        "Go to Date"
                      )}
                      variant="contained"
                      color="primary"
                    />
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </MyDialog>
  );
};

export default DateRangeSelectorModal;
