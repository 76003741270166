import * as React from "react"

const CustomersIcon = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={32} height={32} rx={16} fill="#fff" />
    <path
      d="M19.3 23.734c-.321-.394-4.225-2.27-4.934-2.624-.704-.348-.985-1.311-.985-1.311s-.318.218-.318-.394c0-.613.318.394.635-1.97 0 0 .88-.306.705-2.843h-.211s.528-2.712 0-3.63c-.53-.918-.739-1.53-1.904-1.97-1.163-.437-.74-.35-1.584-.305-.846.044-1.55.613-1.55.918 0 0-.529.044-.74.307-.21.262-.563 1.486-.563 1.792 0 .306.176 2.365.352 2.8l-.21.086c-.175 2.537.705 2.845.705 2.845.317 2.363.634 1.356.634 1.969 0 .612-.317.394-.317.394s-.282.963-.986 1.311c-.704.35-4.612 2.231-4.93 2.624-.317.402-.282 2.238-.282 2.238h7.486l.546-2.673-.485-.603.835-1.04.836 1.04-.486.602.547 2.674h7.485s.04-1.838-.281-2.24v.003Z"
      fill="#E7424C"
      fillOpacity={0.71}
    />
    <path
      d="M29.447 18.4c-.322-.393-4.226-2.27-4.934-2.623-.705-.348-.986-1.312-.986-1.312s-.317.218-.317-.394.317.394.634-1.969c0 0 .88-.306.705-2.843h-.21s.528-2.713 0-3.63c-.531-.919-.74-1.53-1.904-1.97-1.163-.438-.74-.35-1.585-.305-.846.044-1.55.612-1.55.918 0 0-.528.044-.739.306-.211.263-.563 1.487-.563 1.793 0 .306.176 2.365.352 2.8l-.21.085c-.176 2.538.704 2.846.704 2.846.318 2.363.635 1.356.635 1.968 0 .612-.317.395-.317.395s-.282.962-.986 1.31c-.704.35-4.613 2.232-4.93 2.625-.318.402-.282 2.238-.282 2.238h7.486l.546-2.674-.485-.602.835-1.04.835 1.039-.485.602.546 2.674h7.486s.04-1.837-.282-2.239l.001.003Z"
      fill="#E7424C"
      fillOpacity={0.71}
    />
  </svg>
)

export default CustomersIcon
