import * as React from "react";

const DashboardIcon = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={32} height={32} rx={16} fill="#fff" />
    <path
      d="M16.867 12.175v-4.31c0-.305.083-.561.25-.768a.763.763 0 0 1 .617-.31h5.204c.245 0 .451.104.617.31.167.207.25.463.25.768v4.31c0 .305-.083.561-.25.767a.762.762 0 0 1-.617.31h-5.204a.762.762 0 0 1-.617-.31 1.182 1.182 0 0 1-.25-.767Zm-8.672 4.31v-8.62c0-.305.083-.561.25-.768a.763.763 0 0 1 .617-.31h5.204c.245 0 .451.104.618.31.166.207.249.463.249.768v8.62c0 .305-.083.56-.25.767a.765.765 0 0 1-.617.31H9.061a.762.762 0 0 1-.617-.31 1.181 1.181 0 0 1-.25-.767Zm8.672 8.62v-8.62c0-.305.083-.561.25-.768a.763.763 0 0 1 .617-.31h5.204c.245 0 .451.103.617.31.167.207.25.463.25.768v8.62c0 .305-.083.56-.25.767a.763.763 0 0 1-.617.31h-5.204a.763.763 0 0 1-.617-.31 1.182 1.182 0 0 1-.25-.768Zm-8.672 0v-4.31c0-.306.083-.562.25-.769a.763.763 0 0 1 .617-.309h5.204c.245 0 .451.103.618.31.166.206.249.462.249.768v4.31c0 .305-.083.56-.25.767a.765.765 0 0 1-.617.31H9.061a.763.763 0 0 1-.617-.31 1.182 1.182 0 0 1-.25-.768Z"
      fill="#E7424C"
      fillOpacity={0.71}
    />
  </svg>
);

export default DashboardIcon;
