import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
// import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Get } from "../../../actions";
import { Get_GetTotalReservations_URL } from "../../../constants/apiUrls";
import { format } from "../../../heplers/format";
import { getTranslation } from "../../../heplers/translationHelper";

export const DaySelection = ({ selectedDate, setSelectedDate, dayTime }) => {
  const [weekDates, setWeekDates] = useState([]);

  useEffect(() => {
    var startDay = 1; //0=sunday, 1=monday etc.
    const now = new Date();
    var d = now.getDay(); //get the current day
    var startOfWeek = new Date(
      now.valueOf() - (d <= 0 ? 7 - startDay : d - startDay) * 86400000
    ); //rewind to start day
    var endOfWeek = new Date(startOfWeek.valueOf() + 6 * 86400000); //add 6 days to get last day

    const arr = [];

    while (startOfWeek <= endOfWeek) {
      arr.push(startOfWeek.toISOString());
      let newDate = startOfWeek.setDate(startOfWeek.getDate() + 1);
      startOfWeek = new Date(newDate);
    }
    setWeekDates(arr);
  }, []);
  const handlePrev = () => {
    const lastDate = new Date(weekDates[0]);
    // lastDate.setDate(lastDate.getDate()-1)
    const firstDate = new Date(weekDates[0]);
    firstDate.setDate(firstDate.getDate() - 7);
    const arr = [];
    while (!moment(firstDate).isSame(lastDate, "date")) {
      arr.push(firstDate.toISOString());
      firstDate.setDate(firstDate.getDate() + 1);
    }
    setWeekDates(arr);
  };

  const handleNext = () => {
    const firstDate = new Date(weekDates[6]);
    firstDate.setDate(firstDate.getDate() + 1);
    const lastDate = new Date(weekDates[6]);
    lastDate.setDate(firstDate.getDate() + 7);
    const arr = [];
    while (!moment(firstDate).isSame(lastDate, "date")) {
      arr.push(firstDate.toISOString());
      firstDate.setDate(firstDate.getDate() + 1);
    }
    setWeekDates(arr);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      display="flex"
      justifyContent={!isTablet ? "center" : "flex-start"}
      style={{ overflow: "auto" }}
    >
      <ToggleButtonGroup
        // style={
        //   isMobile ? { flexDirection: "column" } : { flexDirection: "row" }
        // }
        fullwidth
        exclusive
        color="primary"
        // value={"14-02-2023"}
        value={selectedDate}
        onChange={(e, value) => {
          if (value) setSelectedDate(value);
        }}
      >
        <Button onClick={handlePrev} color="primary" variant="contained">
          {getTranslation("Prev", "Prev", "Prev")}
        </Button>

        {weekDates.map((date) => (
          <ToggleButton value={format("date", date)}>
            <ShowDayDetails date={date} dayTime={dayTime} />
          </ToggleButton>
        ))}

        <Button onClick={handleNext} color="primary" variant="contained">
          {getTranslation("Next", "Next", "Next")}
        </Button>

        {/* <ShowListOfDays startDate={firstday} endDate={lastday} selectedDate={selectedDate} /> */}
      </ToggleButtonGroup>
    </Box>
  );
};

const ShowDayDetails = ({ date, dayTime }) => {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const { lastUpdateTime } = useSelector((state) => state.userReservation);
  useEffect(() => {
    setLoading(true);
    Get(
      {
        date: moment(date).format("MM-DD-YYYY"),
        dayTimeType: dayTime,
      },
      Get_GetTotalReservations_URL,
      null,
      (resp) => {
        setTotal(resp.data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, [date, dayTime, lastUpdateTime, total]);
  return (
    <Box p={3}>
      <Typography>
        <strong>{getTranslation(moment(date).format("dddd"))}</strong>
      </Typography>
      <Typography>{format("date", date)}</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Typography>
          <strong> {total}</strong>
        </Typography>
      )}
    </Box>
  );
};
