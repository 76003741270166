// Auth
export const LOGIN_URL = "Auth/AppLogin";
export const Post_Register_URL = "Auth/Register";
export const Post_RefreshToken_URL = "Auth/RefreshToken";
export const GET_USER_PROFILE_URL = "Auth/GetUserProfile";
export const Post_UpdateUSER_PROFILE_URL = "Auth/UpdateUserProfile";
export const Post_UpdateUserPassword_URL = "Auth/ChangePassword";
export const Post_VerifyInternalAppLogin_URL = "Auth/VerifyInternalAppLogin";
// export const Get_CustomerApps_URL = "http://appmanagerapi.nativesystemsltd.com/Auth/GetCustomerApps";
export const Get_CustomerApps_URL = "Auth/GetCustomerApps";
export const Get_ServerDateTime_URL = "/SystemSetting/GetServerDateTime";

export const GET_AllUsers_URL = "Auth/GetAllUsersWithPagination";
export const Post_Logout_URL = "Auth/Logout";

// User Reservation

export const Post_Reservation_URL = "api/Reservation/AddReservation";
export const Update_Reservation_URL = "/api/Reservation/UpdateReservation";
export const Get_Reservations_URL = "/api/Reservation/GetAllReservations";
export const Get_Reservation_URL = "/api/Reservation/GetReservation";
export const Get_All_Print_Reservation_URL ="/api/Reservation/GetAllPrintReservations";
export const Delete_Reservations_URL = "/api/Reservation/DeleteReservation";
export const Get_GetTotalReservations_URL = "/api/Reservation/GetTotalReservations";
export const Get_ResturentCapacityStatus_URL = "/api/Reservation/ResturentCapacityStatus";
export const Get_VerifyUpdateReservation_URL = "/api/Reservation/VerifyUpdateReservation";


// Language Translation

export const Get_LanguageDictionary_URL = `Language/GetLanguageDictionary`;
export const Post_AddNewText_URL = `Language/AddNewText`;

// File Manager URL's
export const Post_FileUpload_URL = "FileManager/Upload";
export const Get_File_URL = "FileManager/Get";
export const Get_DeleteFile_URL = "FileManager/DeleteFile";
export const Get_FileInfo_URL = "FileManager/GetFileInfo";

// Time Slots

export const Get_All_TimeSlots_URL = "api/Reservation/GetTimeSlots";
export const Get_GetAvailableTimeSlots_URL ="api/Reservation/GetAvailableTimeSlots";
export const Upsert_TimeSlots_URL = "/api/Reservation/UpsertTimeSlot";
export const Delete_TimeSlots_URL = "/api/Reservation/DeleteTimeSlot";
export const Get_TimeSlotsById_URL = "/api/Reservation/GetTimeSlotById"

// Customers
export const Get_All_Customers_URL = "/Customer/GetAllCustomers";
export const Upsert_Customers_URL = "/Customer/UpsertCustomer";
export const Delete_Customers_URL = "/Customer/DeleteCustomer";

// Max Capacities
export const Get_MaxCapacity_URL = "/api/Reservation/GetMaxCapacities";
export const Upsert_MaxCapacity_URL = "/api/Reservation/UpsertMaxCapacity";
export const Delete_MaxCapacity_URL = "/api/Reservation/DeleteMaxCapacity";

//Days-off
export const Get_All_DaysOff_URL = "/api/Reservation/GetAllDaysOff";
export const Get_CalendarDaysOff_URL = "/api/Reservation/GetAllCalenderDaysOff";
export const Upsert_DaysOff_URL = "/api/Reservation/UpsertDayOff";
export const Delete_DaysOff_URL = "/api/Reservation/DeleteDayOff";

// Close Services
export const Post_CloseService_URL = "/api/Reservation/CloseService";
export const Get_CloseService_URL = "/api/Reservation/CloseServiceStatus";