import { makeStyles } from "@material-ui/core";

// default primary #E7424C
// default secondary rgba(255, 35, 35, 0.83)
const userReservationStyles = makeStyles(
  {
    primaryColor:props=>props.primaryColor,
    root:props=>( {
      "& .MuiStepIcon-active": { color: props.primaryColor },
      "& .MuiStepIcon-completed": { color: "lightgreen" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "lightgray" }
    }),
  navbar: props=>({
    width: "100%",
    height: "5.6rem",
    background:
      `linear-gradient(270deg, ${props.secondaryColor} 36.64%, ${props.primaryColor} 63.1%);`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2rem 12rem",
    position: "sticky",
    top: "0",
    left: "0",

    "@media (max-width: 1000px)": {
      height: "4.5rem",
      padding: "0 2rem",
    },
  }),
  navbarMobile: {
    width: "100%",
    height: "4.6rem",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 1rem",
    position: "sticky",
    top: "0",
    left: "0",
  },
  langSelector: {},
  logoWrapper: {
    width: "7rem",
    height: "5rem",
    borderRadius: "100%",

    "@media (max-width: 1000px)": {
      height: "4rem",
      width: "5rem",
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  layoutWrapper: {
    width: "100%",
    height: "100vh",
    position: "relative",
    top: "0",
    left: "0",
    overflow: "hidden",
  },
  main: {
    width: "100%",
    height: "calc(100vh - 10rem)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",

    "@media (max-width: 1000px)": {
      height: "90vh",
      paddingBottom: "8rem",
    },
  },
  footer: props=>( {
    width: "100%",
    height: "5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: props.primaryColor,
    position: "fixed",
    bottom: "0",
    left: "0",

    "@media (max-width: 1000px)": {
      display: "none",
    },
  }),
  footerLogoWrapper: {
    width: "7rem",
    height: "5rem",
    borderRadius: "100%",
  },
  formWrapper: {
    width: "35%",
    maxWidth: "40rem",

    "@media (max-width: 1000px)": {
      width: "90%",
    },
  },
  editFormCard: {
    width: "100%",
    backgroundColor: "white",
  },
  formCard: {
    width: "100%",
    // minHeight: "35rem",
    boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.22)",
    borderRadius: "10px",
    backgroundColor: "white",
    margin: "2rem auto",
    padding: "2rem",
  },
  titleCont: {
    width: "48rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",

    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  title: {
    textAlign: "center",
    textTransform: "upperCase",
  },
  titleDescription: {
    textAlign: "center",
    fontSize: "1.2rem",
  },
  stepperWrapper: {
    width: "100%",
  },
  form: {
    "@media (max-width: 1000px)": {
      width: "100%",
    },
  },
  inputsWrapper: {
    marginTop: "1rem",
    width: "100%",
    padding: "1rem 0",
    display: "flex",
    justifyContent: "center",
  },
  inputConts: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "1rem",

    "@media (max-width: 1000px)": {
      gridTemplateColumns: "1fr",
    },
  },
  datePicker: {
    // marginTop: "-0.39rem",

    "@media (max-width: 1000px)": {
      marginTop: "1rem",
    },
  },
  radioWrapper: {
    marginTop: "1rem",
  },
  radio:theme=>({
    color: 'gray',
    '&$checked': {
      color: '#4B8DF8'
    }
  }),
  checked: {},
  timeCardWrapper: {
    display: "flex",
    alignItems: "center",
  },
  timeBtn: {
    border: "1px solid #464646",
    backgroundColor: "white",
    borderRadius: "5px",
    width: "max-content",
    padding: "0.5rem 0.7rem",
    // marginRight: "1rem",
    cursor: "pointer",
  },
  btnIsSelected:props=>( {
    backgroundColor: props.primaryColor,
    color: "white",
    borderRadius: "5px",
    width: "max-content",
    padding: "0.5rem 0.7rem",
    border: "none",
    // marginRight: "1rem",
    cursor: "pointer",
  }),
  timeSlotsWrapper: {
    margin: "1.5rem 0",
    width: "100%",
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: "repeat(auto-fill, minmax(5rem, 1fr))",
  },
  input: {
    marginTop: "1rem",
  },
  submitBtn: {
    marginRight: "1rem",
  },
  btnWrapper: {
    marginTop: "1rem",
  },
  emptyTimeSlot: {
    margin: "0.4rem 0",
    display: "flex",
    justifyContent: "center",
  },
  timeSlotCont: {
    margin: "1rem 0",
  },
  submitBtn:{
    color:"green",
  },
  checkBox:(props)=>({
    color:props.primaryColor,
  })
});

export default userReservationStyles;
