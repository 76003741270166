import { Box, Button } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { styled } from "@material-ui/styles";
import { StyledToggleButtonGroup } from "../../../components";
import { getTranslation } from "../../../heplers/translationHelper";

export const FeaturesFilter = ({ selectedTab, setSelectedTab }) => {
  return (
    <Box display="flex" justifyContent="center">
      {" "}
      <StyledToggleButtonGroup value={selectedTab} color="success">
        <ToggleButton
          color="secondary"
          value="maxCapacity"
          onClick={() => setSelectedTab("maxCapacity")}
        >
          {getTranslation("Maximum Capacity","Maximum Capacity","Maximum Capacity")}
        </ToggleButton>
        <ToggleButton
          color="secondary"
          value="daysOff"
          onClick={() => setSelectedTab("daysOff")}
        >
          {getTranslation("Days Off","Days Off","Days Off")}
          
        </ToggleButton>
        <ToggleButton
          color="secondary"
          value="timeSlots"
          onClick={() => setSelectedTab("timeSlots")}
        >
          {getTranslation("Time Slots","Time Slots","Time Slots")}
          
        </ToggleButton>
        {/* <ToggleButton color="secondary" value="maxgroup">
          Maximum Group Per 1/4 hour
        </ToggleButton> */}
      </StyledToggleButtonGroup>
    </Box>
  );
};
